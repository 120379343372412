import React from 'react'
import { FunctionComponent } from 'Root/EasyRedmineGatsby/node_modules/@types/react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import PuxRepeater, { PuxRepeaterType } from '../PuxRepeater/PuxRepeater'

export interface WidgetPuxRepeaterType extends WidgetBase, PuxRepeaterType {
  contentType: 'PuxSimpleRepeater'
}

const WidgetPuxRepeater: FunctionComponent<WidgetPuxRepeaterType> = (props) => {
  return (
    <WidgetContainer {...props.puxWidgetProperties} {...props.metadata}>
      <PuxRepeater {...props} />
    </WidgetContainer>
  )
}

export default WidgetPuxRepeater
